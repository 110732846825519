import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../config/firebase';
import { toastNotifyError } from '../components/Common/Toast';

// Initialize Firebase
initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
const auth = getAuth();

export const googleSignIn = () => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const user = result.user;
      const [firstName, ...lastName] = user?.displayName?.split(' ') || ['', ''];
      const payload = {
        email_id: user.email,
        first_name: firstName,
        last_name: lastName.join(' '),
        sign_in_id: user.uid,
        photo: user.photoURL,
      };
      // auth.currentUser.getIdToken(true);
      googleSignOut();
      return payload;
    })
    .catch((error) => {
      // Handle Errors here.
      //   const credential = GoogleAuthProvider.credentialFromError(error);
      toastNotifyError(error);
    });
};

export const googleSignOut = () => {
  signOut(auth);
};
