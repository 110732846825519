import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { DEFAULT_SSR_IMAGE_URL } from '@/utils/constants/images';
import Modal from '../../Common/Modal';
import { toastNotifyError } from '../../Common/Toast';
import {
  getB2bRfqInputFields,
  loginWithGmail,
  truecallerAbsent,
} from '../../../redux/actions';

import Button from '../AtomicComponents/Button';
import { getLeadData } from '@/utils/localStorageFunctions';
import useWindowDimensions from '@/utils/getWindowDimension';
import truecallerPopup from './Truecaller';
import { EMAIL_REGEX } from '@/utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';
import OtpInput from 'react-otp-input';
import { googleSignIn } from '@/utils/gmailLogin';
import useAuthenticate from '@/hooks/useAuthenticate';

const MAXIMUM_VERIFY_OTP_TRIES_ALLOWED = 3;

const AuthModal = (props) => {
  const { width } = useWindowDimensions();
  const { storeInfo, emailLoginFeatureStatus, indianMobileLoginFeatureStatus, user } =
    useSSRSelector((state) => ({
      storeInfo: state.storeReducer.store,
      emailLoginFeatureStatus: state?.storeReducer?.store?.services?.email_login,
      indianMobileLoginFeatureStatus:
        state?.storeReducer?.store?.services?.indian_mobile_login,
      user: state.userReducer,
    }));

  const {
    otp,
    isOtpSent,
    timeLeft,
    invalidOtp,
    setOtp,
    setIsOtpSent,
    setInvalidOtp,
    sendOtp,
    verifyOtp,
  } = useAuthenticate(59);

  const dispatch = useDispatch();
  const verifyOtpTries = useRef(0);
  const router = useRouter();

  const [state, setState] = useState({
    phone: '',
    email: '',
    currentDeviceWidth: 0,
    isLoginByMobileNumber: true,
    disableSendOtpAfterOneClick: false,
    disableVerifyOtpAfterOneClick: false,
  });

  const canVerifyOtp = isOtpSent && !invalidOtp && otp.length === 4;

  useEffect(() => {
    if (indianMobileLoginFeatureStatus === 0) {
      toggleCurrentLoginType();
    }
  }, [indianMobileLoginFeatureStatus]);

  useEffect(() => {
    if (props.isVisible) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }
  }, [props.isVisible]);

  useEffect(() => {
    if (props?.isPhoneNumber) {
      const phone = getLeadData();
      setState((state) => ({ ...state, phone: phone }));
      sendOtp(phone, false);
    }
    dispatch(truecallerAbsent());
  }, []);

  useEffect(() => {
    if (user?.isLogin) {
      props.toggleModal();
    }
  }, [user]);

  useEffect(() => {
    if (props.isVisible && props.sendOTPBeforeHand && props.prefilledPhone) {
      setState((state) => ({ ...state, phone: props.prefilledPhone }));
      setPhoneNumber(props.prefilledPhone);
    }
  }, [props.isVisible, props.prefilledPhone]);

  useEffect(() => {
    setState((state) => ({ ...state, currentDeviceWidth: width }));
  }, [width]);

  const getRegexByType = (type) => {
    switch (type) {
      case 'email':
        return /[^\w\@\-\.]/g;
      case 'alpha_numeric':
        return /[^\w/]/gi;
      case 'alpha_space':
        return /[^a-z ]/gi;
      case 'address':
        return /[^\w\s\-\,]/gi;
      default:
        return /[^\d]/gi;
    }
  };

  const handleInputChange = (key, value, validationType, minLength, maxLength) => {
    if (value.length > maxLength) value = value.substring(0, maxLength);
    value = value.replace(getRegexByType(validationType), '');

    if (!isNaN(value) || key === 'email') {
      switch (key) {
        case 'phone': {
          setState((state) => ({ ...state, phone: value }));
          break;
        }
        case 'email': {
          setState((state) => ({ ...state, email: value }));
          break;
        }
        case 'otp': {
          invalidOtp && setInvalidOtp(false);
          setOtp(value);
          break;
        }
        default: {
          console.log('Not a valid key!');
          break;
        }
      }
    }
  };

  function disableSendOtpAfterOneClick() {
    setState((data) => ({ ...data, disableSendOtpAfterOneClick: true }));
    setTimeout(() => {
      setState((data) => ({ ...data, disableSendOtpAfterOneClick: false }));
    }, 1000);
  }

  const setPhoneNumber = (phoneNo) => {
    const phoneReg = /(6|7|8|9)\d{9}/;
    const phone = state.phone || phoneNo;
    if (phone && phone.length === 10 && phone.match(phoneReg)) {
      sendOtp(phone, false);
      disableSendOtpAfterOneClick();
    } else {
      toastNotifyError('Invalid Phone Number!');
    }
  };

  const setEmailId = (emailId) => {
    const email = emailId || state.email;
    if (email && email.match(EMAIL_REGEX)) {
      sendOtp(email, true, _requestConfigArgs());
      disableSendOtpAfterOneClick();
    } else {
      toastNotifyError('Invalid Email ID!');
    }
  };

  const resendOTP = () => {
    const isLoginByEmail = !state.isLoginByMobileNumber;
    const contact = isLoginByEmail ? state.email : state.phone;
    sendOtp(contact, isLoginByEmail, _requestConfigArgs());
  };

  const verifyOtpCallback = (status) => {
    if (!status) {
      verifyOtpTries.current = verifyOtpTries.current + 1;
      if (verifyOtpTries.current === MAXIMUM_VERIFY_OTP_TRIES_ALLOWED) {
        verifyOtpTries.current = 0;
        toggleOTPsent();
      } else setInvalidOtp(true);
    } else {
      dispatch(getB2bRfqInputFields());
      props?.afterOtpVerifyCallback?.();
    }
    setState((data) => ({ ...data, disableVerifyOtpAfterOneClick: false }));
  };

  const submitVerifyOTP = () => {
    if (otp.length === 4) {
      verifyOtp(..._requestConfigArgs());
      setState((data) => ({ ...data, disableVerifyOtpAfterOneClick: true }));
    } else {
      toastNotifyError('Please enter valid OTP');
    }
  };

  function _requestConfigArgs() {
    const isEmailLogin = !state.isLoginByMobileNumber;
    const contact = isEmailLogin ? state.email : state.phone;
    return [verifyOtpCallback, true, contact, isEmailLogin, !!props.redirection];
  }

  const signIn = async () => {
    try {
      const gmailAccountData = await googleSignIn();
      const data = {
        email: gmailAccountData.email_id,
      };
      dispatch(loginWithGmail(data, router, props.redirection ? true : false));
    } catch (err) {
      toastNotifyError(err);
    }
  };

  const toggleOTPsent = () => {
    setInvalidOtp(false);
    setIsOtpSent(!isOtpSent);
  };

  const toggleCurrentLoginType = () => {
    setState((state) => ({
      ...state,
      phone: '',
      email: '',
      isLoginByMobileNumber: !state.isLoginByMobileNumber,
    }));
    isOtpSent && setIsOtpSent(false);
    invalidOtp && setInvalidOtp(false);
    otp && setOtp('');
  };

  function activeCloseFunction() {
    if (props.isFromCart) return props.handleOnCloseButton();
    else return props.toggleModal();
  }

  const formObject = state.isLoginByMobileNumber
    ? {
        preOTP: {
          labelText: 'Mobile Number',
          inputType: 'number',
          inputValue: state.phone,
          inputPlaceholder: 'Enter your Mobile Number',
          inputChangeHandler: (e) =>
            handleInputChange(
              'phone',
              e.target.value.replace(/[^0-9]/g, ''),
              'alpha_numeric',
              1,
              10
            ),
          buttonBackground:
            state.phone.length == 10 && !state.disableSendOtpAfterOneClick
              ? '#2F80ED'
              : '#00000033',
          isButtonDisable: state.phone.length < 10 || state.disableSendOtpAfterOneClick,
          clickHandler: setPhoneNumber,
        },
        duringOTP: {
          contact: state.phone,
          editIcon: '/assets/images/Edit.svg',
          editContactText: 'Edit number',
          handleInputChange: (otp) =>
            handleInputChange('otp', otp.replace(/[^0-9]/g, ''), 'alpha_numeric', 1, 4),
          toggleOTPsent: toggleOTPsent,
          inputValue: otp,
          resendOTP: resendOTP,
          buttonBackground:
            canVerifyOtp && !state.disableVerifyOtpAfterOneClick
              ? '#2F80ED'
              : '#00000033',
          isButtonDisable: !canVerifyOtp || state.disableVerifyOtpAfterOneClick,
          submitVerifyOTP: submitVerifyOTP,
        },
        toggleLoginButtonIcon: '/assets/images/email-black-white.svg',
        toggleLoginButtonText: 'Login with Email ID',
      }
    : {
        preOTP: {
          labelText: 'Email ID',
          inputType: 'email',
          inputValue: state.email,
          inputPlaceholder: 'Enter your Email ID',
          inputChangeHandler: (e) =>
            handleInputChange('email', e.target.value, 'email', 1, 320),
          buttonBackground:
            state.email.match(EMAIL_REGEX) && !state.disableSendOtpAfterOneClick
              ? '#2F80ED'
              : '#00000033',
          isButtonDisable:
            !state.email.match(EMAIL_REGEX) || state.disableSendOtpAfterOneClick,
          clickHandler: setEmailId,
        },
        duringOTP: {
          contact: state.email,
          editIcon: '/assets/images/Edit.svg',
          editContactText: 'Edit email',
          toggleOTPsent: toggleOTPsent,
          inputValue: otp,
          handleInputChange: (otp) =>
            handleInputChange('otp', otp.replace(/[^0-9]/g, ''), 'alpha_numeric', 1, 4),
          resendOTP: resendOTP,
          isButtonDisable: !canVerifyOtp || state.disableVerifyOtpAfterOneClick,
          buttonBackground:
            canVerifyOtp && !state.disableVerifyOtpAfterOneClick
              ? '#2F80ED'
              : '#00000033',
          submitVerifyOTP: submitVerifyOTP,
        },
        toggleLoginButtonIcon: '/assets/images/phone-blue-outline.svg',
        toggleLoginButtonText: 'Login with Mobile Number',
      };

  return state.currentDeviceWidth < 766 ? (
    <PremiumMobileAuth
      OTPsent={isOtpSent}
      invalidOtp={invalidOtp}
      timer={timeLeft}
      storeInfo={storeInfo}
      activeCloseFunction={activeCloseFunction}
      indianMobileLoginFeatureStatus={indianMobileLoginFeatureStatus}
      emailLoginFeatureStatus={emailLoginFeatureStatus}
      isLoginByMobileNumber={state.isLoginByMobileNumber}
      toggleCurrentLoginType={toggleCurrentLoginType}
      formObject={formObject}
      signIn={signIn}
      {...props}
    />
  ) : (
    <PremiumDesktopAuth
      OTPsent={isOtpSent}
      invalidOtp={invalidOtp}
      timer={timeLeft}
      storeInfo={storeInfo}
      activeCloseFunction={activeCloseFunction}
      indianMobileLoginFeatureStatus={indianMobileLoginFeatureStatus}
      emailLoginFeatureStatus={emailLoginFeatureStatus}
      isLoginByMobileNumber={state.isLoginByMobileNumber}
      toggleCurrentLoginType={toggleCurrentLoginType}
      formObject={formObject}
      signIn={signIn}
      {...props}
    />
  );
};

const PremiumMobileAuth = (props) => {
  const { formObject } = props;
  return (
    <Modal
      visible={props.isVisible}
      className="bottom-modal margin-top-modal-0"
      animation={'slideUp'}
      onClose={props.toggleModal}
      closeMaskOnClick={
        !props.sendOTPBeforeHand ? (props.isFromCart ? false : true) : false
      }
      customStyles={{
        padding: '24px 20px 80px 20px',
        maxHeight: '80%',
      }}
    >
      <div className="flex flex-column premium-auth-modal-wrap">
        {props.OTPsent ? (
          <>
            {!props.sendOTPBeforeHand ? (
              <div
                className="flex justify-end closeicon"
                onClick={props.activeCloseFunction}
              >
                <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
              </div>
            ) : null}
            <div className="flex flex-column" style={{ gap: '32px' }}>
              <div className="flex flex-column login-otp-number-wrap">
                <div className="f20px fw7 otp-header"> OTP sent to </div>
                <div className="flex justify-between flex-wrap items-center contact-edit-btn-wrapper">
                  <div className="f24px fw7">{formObject.duringOTP.contact}</div>
                  {!props.prefilledPhone ? (
                    <div
                      className="flex pointer edit-btn"
                      onClick={() => formObject.duringOTP.toggleOTPsent()}
                    >
                      <img
                        src={formObject.duringOTP.editIcon}
                        alt="edit"
                        height="18px"
                        width="18px"
                      />
                      <div className="f16px fw6">
                        {formObject.duringOTP.editContactText}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="otp-form-group form-group flex flex-column">
                <div className="flex flex-column input-resend-wrapper">
                  <div className="flex flex-column label-input-wrapper">
                    <label className="f14px fw6">Enter OTP</label>
                    <OtpInput
                      value={formObject.duringOTP.inputValue}
                      onChange={formObject.duringOTP.handleInputChange}
                      numInputs={4}
                      separator={<span> </span>}
                      containerStyle={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        gap: '16px',
                      }}
                      shouldAutoFocus={true}
                      inputStyle={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        padding: '6px 12px',
                        gap: '10px',
                        borderRadius: 6,
                        flex: '1 0 0',
                        border: '1px solid #E9E9E9',
                      }}
                      isInputNum
                    />
                  </div>
                  <div className="flex justify-between message-resend-wrapper">
                    <div
                      style={
                        props.invalidOtp
                          ? { color: 'red' }
                          : {
                              visibility: 'hidden',
                            }
                      }
                    >
                      Invalid OTP
                    </div>
                    {props.timer ? (
                      <div className="timer db f16px fw4">
                        Resend in {`0:${props.timer}`}
                      </div>
                    ) : (
                      <div className="flex justify-end f12px">
                        <span
                          className="blue fw6 f16px pointer"
                          onClick={formObject.duringOTP.resendOTP}
                        >
                          Resend OTP
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Button
              style={{ border: 'none', borderRadius: '4px' }}
              width="100%"
              py="10px"
              px="24px"
              fontSize={14}
              backgroundColor={formObject.duringOTP.buttonBackground}
              roundness={props.storeInfo?.theme?.roundness}
              disabled={formObject.duringOTP.isButtonDisable}
              onClick={() => formObject.duringOTP.submitVerifyOTP()}
              label="Verify OTP"
            />
          </>
        ) : (
          <>
            {!props.sendOTPBeforeHand ? (
              <span
                className="flex justify-end closeicon"
                onClick={props.activeCloseFunction}
              >
                <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
              </span>
            ) : null}
            <div className="flex flex-column" style={{ gap: '32px' }}>
              <div className="form-header flex flex-column">
                <div className="flex">
                  <img
                    src={props.storeInfo?.logo_image || DEFAULT_SSR_IMAGE_URL}
                    alt="logo"
                    height="80px"
                    width="80px"
                  />
                </div>
                <div className="header-text flex flex-column">
                  <div
                    className="silver f20px fw5"
                    style={{ transform: 'translateY(2px)' }}
                  >
                    Welcome to {props.storeInfo?.store_info?.name}
                  </div>
                  <div className="f28px fw7">Login</div>
                </div>
              </div>
              <div className="form-container flex flex-column self-strech">
                <div className="form-group flex flex-column">
                  <label className="f14px fw6 silver">
                    {formObject.preOTP.labelText}
                  </label>
                  <input
                    onFocus={truecallerPopup}
                    type={formObject.preOTP.inputType}
                    className="bg-white form-control f14px"
                    value={formObject.preOTP.inputValue}
                    placeholder={formObject.preOTP.inputPlaceholder}
                    onChange={formObject.preOTP.inputChangeHandler}
                  />
                  {/* <img
                  loading="lazy"
                  src="/assets/images/whatsapp.png"
                  alt="whatapp"
                /> */}
                </div>
                {props.isLoginByMobileNumber && (
                  <span className="bottom-txt">
                    Yes, I want to receive important information & updates on my
                    WhatsApp/SMS
                  </span>
                )}
              </div>
            </div>
            <Button
              style={{ border: 'none', borderRadius: '4px' }}
              roundness={props.storeInfo?.theme?.roundness}
              fluid={true}
              width="100%"
              py="10px"
              px="24px"
              fontSize={14}
              backgroundColor={formObject.preOTP.buttonBackground}
              disabled={formObject.preOTP.isButtonDisable}
              onClick={() => formObject.preOTP.clickHandler()}
              label="Get OTP"
            />
          </>
        )}
        {props.emailLoginFeatureStatus && props.indianMobileLoginFeatureStatus ? (
          <>
            <div className="flex flex-center separator">
              <hr className="line" />
              <span className="text">OR</span>
              <hr className="line" />
            </div>
            <div className="flex flex-column w-100 additional-login-options">
              {props.indianMobileLoginFeatureStatus ? (
                <button
                  className="flex justify-center items-center email-login-button pointer"
                  onClick={props.toggleCurrentLoginType}
                >
                  <img
                    className="flex-shrink-0 icon"
                    src={formObject.toggleLoginButtonIcon}
                  />
                  <span className="fw6 button-text">
                    {formObject.toggleLoginButtonText}
                  </span>
                </button>
              ) : null}
              {/* <button
                className="flex justify-center items-center email-login-button pointer"
                onClick={props.signIn}
              >
                <img
                  className="flex-shrink-0 icon"
                  src="/assets/images/google-logo-xs.svg"
                  alt="google-logo"
                />
                <span className="fw6 button-text"> Login with Gmail</span>
              </button> */}
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

const PremiumDesktopAuth = (props) => {
  const { formObject } = props;
  return (
    <Modal
      visible={props.isVisible}
      className="bottom-modal"
      animation="center"
      onClose={props.activeCloseFunction}
      closeMaskOnClick={false}
      customStyles={{
        padding: '32px 24px',
        maxHeight: '100%',
        overflow: 'hidden',
      }}
    >
      <div className="premium-auth-modal-wrap overflow-hidden">
        {props.OTPsent ? (
          <>
            {!props.sendOTPBeforeHand ? (
              <span className="closeicon" onClick={props.activeCloseFunction}>
                <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
              </span>
            ) : null}
            <div className="flex flex-column login-otp-number-wrap">
              <div className="f20px fw7 otp-header"> OTP sent to </div>
              <div className="flex justify-between flex-wrap items-center contact-edit-btn-wrapper">
                <div className="f28px fw7">{formObject.duringOTP.contact}</div>
                {!props.prefilledPhone ? (
                  <div
                    className="flex pointer edit-btn"
                    onClick={() => formObject.duringOTP.toggleOTPsent()}
                  >
                    <img
                      src={formObject.duringOTP.editIcon}
                      alt="edit"
                      height="18px"
                      width="18px"
                    />
                    <div className="f16px fw6">
                      {formObject.duringOTP.editContactText}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="otp-form-group form-group flex flex-column">
              <div className="flex flex-column input-resend-wrapper">
                <div className="flex flex-column label-input-wrapper">
                  <label className="f14px fw6">Enter OTP</label>
                  <OtpInput
                    value={formObject.duringOTP.inputValue}
                    onChange={formObject.duringOTP.handleInputChange}
                    numInputs={4}
                    separator={<span> </span>}
                    containerStyle={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      gap: '16px',
                    }}
                    shouldAutoFocus={true}
                    inputStyle={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      padding: '6px 12px',
                      gap: '10px',
                      borderRadius: 6,
                      flex: '1 0 0',
                      border: '1px solid #E9E9E9',
                      backgroundColor: 'white',
                    }}
                    isInputNum
                  />
                </div>
                <div className="flex justify-between message-resend-wrapper">
                  <div
                    style={
                      props.invalidOtp
                        ? { color: 'red' }
                        : {
                            visibility: 'hidden',
                          }
                    }
                  >
                    Invalid OTP
                  </div>
                  {props.timer ? (
                    <div className="timer db f16px fw4">
                      Resend in {`0:${props.timer}`}
                    </div>
                  ) : (
                    <div className="flex justify-end f12px">
                      <span
                        className="blue fw6 f16px pointer"
                        onClick={formObject.duringOTP.resendOTP}
                      >
                        Resend OTP
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <Button
                style={{ border: 'none', borderRadius: '4px' }}
                width="100%"
                py="10px"
                px="24px"
                fontSize={14}
                backgroundColor={formObject.duringOTP.buttonBackground}
                roundness={props.storeInfo?.theme?.roundness}
                disabled={formObject.duringOTP.isButtonDisable}
                onClick={() => formObject.duringOTP.submitVerifyOTP()}
                label="Verify OTP"
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-header flex flex-column">
              <div className="flex">
                <img
                  src={props.storeInfo?.logo_image || DEFAULT_SSR_IMAGE_URL}
                  alt="logo"
                  height="80px"
                  width="80px"
                />
              </div>
              <div className="header-text flex flex-column">
                <div
                  className="silver f20px fw5"
                  style={{ transform: 'translateY(2px)' }}
                >
                  Welcome to {props.storeInfo?.store_info?.name}
                </div>
                <div className="f28px fw7">Login</div>
              </div>
            </div>
            {!props.sendOTPBeforeHand ? (
              <span className="closeicon" onClick={props.activeCloseFunction}>
                <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
              </span>
            ) : null}
            <div className="form-container flex flex-column self-strech">
              <div className="form-group flex flex-column">
                <label className="f14px fw6 silver">{formObject.preOTP.labelText}</label>
                <input
                  type={formObject.preOTP.inputType}
                  className="bg-white form-control f14px"
                  value={formObject.preOTP.inputValue}
                  placeholder={formObject.preOTP.inputPlaceholder}
                  onChange={formObject.preOTP.inputChangeHandler}
                />
                {/* <img
                loading="lazy"
                src="/assets/images/whatsapp.png"
                alt="whatapp"
              /> */}
              </div>
              {props.isLoginByMobileNumber && (
                <span className="bottom-txt">
                  Yes, I want to receive important information & updates on my
                  WhatsApp/SMS
                </span>
              )}
              <Button
                style={{ border: 'none', borderRadius: '4px' }}
                roundness={props.storeInfo?.theme?.roundness}
                width="100%"
                py="10px"
                px="24px"
                fontSize={14}
                backgroundColor={formObject.preOTP.buttonBackground}
                disabled={formObject.preOTP.isButtonDisable}
                onClick={() => formObject.preOTP.clickHandler()}
                label="Get OTP"
              />
            </div>
          </>
        )}
        {props.emailLoginFeatureStatus && props.indianMobileLoginFeatureStatus ? (
          <>
            <div className="flex flex-center separator">
              <hr className="line" />
              <span className="text">OR</span>
              <hr className="line" />
            </div>
            <div className="flex flex-column w-100 additional-login-options">
              {props.indianMobileLoginFeatureStatus ? (
                <button
                  className="flex justify-center items-center email-login-button pointer"
                  onClick={props.toggleCurrentLoginType}
                >
                  <img
                    className="flex-shrink-0 icon"
                    src={formObject.toggleLoginButtonIcon}
                  />
                  <span className="fw6 button-text">
                    {formObject.toggleLoginButtonText}
                  </span>
                </button>
              ) : null}
              {/* <button
                className="flex justify-center items-center email-login-button pointer"
                onClick={props.signIn}
              >
                <img
                  className="flex-shrink-0 icon"
                  src="/assets/images/google-logo-xs.svg"
                  alt="google-logo"
                />
                <span className="fw6 button-text"> Login with Gmail</span>
              </button> */}
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default AuthModal;
