import { toastNotifyError } from '@/components/Common/Toast';
import { authTruecallerDetails, truecallerPresent } from '@/redux/actions';
import store from '@/redux/store';
import { getRequestNounce } from '@/utils/getRequestNounce';
import { TRUECALLER_SESSION_ERROR } from '@/utils/constants';
import { getMobileOperatingSystem } from '@/utils/operatingSystem';

/**
 * function Contains a Deeplink that invokes truecaller verification modal from
 * truecaller app (works for android alone)
 *
 * function invokes on onFocus is triggered from authentication Modal. If Truecaller app is
 * is Present it invokes a verfication modal from truecaller app.
 *
 * If modal successfully opens broswer document will lose focus and we start the polling process.
 * else OTP verification flows continues.
 *
 * authication api is called every 3 seconds after truecaller popup opens till its closed.
 *
 *     countForPollCall == Max number of api calls allowed to authenticate user in a session.
 *     requestNonce == Unique-id associated with user login attempt.
 *
 *     TRUECALLER_PARTNER_KEY == Dotpe partner key for truecaller-verification.Value acquired
 *                               from environment variable from .env file.
 *
 *      Popup == contains truecaller deeplink. Params are passed that dictate UI of truecaller popup
 *              along with unique-id , partnerKey.
 *
 *      Corrected_theme_color == "#" removed string passed as params to deeplink for button color.
 *
 *      setCountForPollCall == sets api call counter.
 *      decrementCountForPollCall == decrease the counter for Pollcall by 1
 *      CheckCountForPollcall == checks api call limit reached in a session.
 *
 *      hash character '#' in deeplink doesnt work.
 */

let countForPollCall = 0;

function truecallerPopup() {
  const mobileOsName = getMobileOperatingSystem();
  const theme_color =
    store.getState()?.storeReducer?.store?.theme?.colors?.primary_color || '#000000';
  const isTruecallerPresent = store.getState().userReducer.truecallerPresent;
  const corrected_theme_color = theme_color.slice(1, theme_color.length);
  function checkCountForPollCall() {
    if (countForPollCall === 0) return false;
    return true;
  }
  function setCountForPollCall() {
    countForPollCall = 5;
  }
  function decrementCountForPollCall() {
    countForPollCall--;
  }

  if (typeof window !== 'undefined') {
    const requestNonce = getRequestNounce();
    setCountForPollCall();
    const TRUECALLER_PARTNER_KEY = process.env.NEXT_PUBLIC_TRUECALLER_PARTNER_KEY;
    const popup = `truecallersdk://truesdk/web_verify?type=btmsheet&requestNonce=${requestNonce}&partnerKey=${TRUECALLER_PARTNER_KEY}&partnerName=Digital Showroom&lang=en&loginPrefix=getstarted&loginSuffix=verifymobile&ctaPrefix=use&ctaColor=%23${corrected_theme_color}&ctaTextColor=%23ffffff&btnShape=round&skipOption=useanothernum`;
    if (mobileOsName !== 'iOS' && !isTruecallerPresent) window.location.href = popup;

    setTimeout(function () {
      if (!document.hasFocus()) {
        store.dispatch(truecallerPresent());
        const pollIntervalId = setInterval(() => {
          if (checkCountForPollCall() && !document.hasFocus()) {
            store.dispatch(authTruecallerDetails(requestNonce));
            decrementCountForPollCall();
          } else if (checkCountForPollCall() && document.hasFocus()) {
            store.dispatch(authTruecallerDetails(requestNonce));
            clearInterval(pollIntervalId);
          } else {
            clearInterval(pollIntervalId);
            toastNotifyError(TRUECALLER_SESSION_ERROR);
          }
        }, 3000);
      }
    }, 1000);
  }
}

export default truecallerPopup;
