export const firebaseConfig = {
  apiKey: 'AIzaSyBfwMOR-NnzzSfRmocjc39EImHGN7VLXjk',
  authDomain: 'new-digitaldukaan.firebaseapp.com',
  databaseURL: 'https://new-digitaldukaan.firebaseio.com',
  projectId: 'new-digitaldukaan',
  storageBucket: 'new-digitaldukaan.appspot.com',
  messagingSenderId: '474446874433',
  appId: '1:474446874433:web:6d883afb77c191ca63c3c0',
  measurementId: 'G-SJWXPQ8T9V',
};
