import store from '../redux/store';

/**
 * function returns a unique string composed of
 * two times timeStamp and storeID separated by a '-'
 * used in truecaller deeplink to get unique reques id
 * @return {String}
 */
export function getRequestNounce() {
  const timeStamp = new Date().getTime();
  const storeId = store.getState().storeReducer.store.store_id;
  if (!storeId) return '';
  const requestNounce =
    timeStamp.toString() + timeStamp.toString() + '-' + storeId.toString();
  return requestNounce;
}
